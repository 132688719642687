import EnvProvider from "@tt/vue-environment/EnvProvider";
import createClient from "./apiClient.js";
import baseApiService from "./baseApiService.js";
import apiServiceHelpers from "@/services/apiServiceHelpers";

const apiClient = createClient(EnvProvider.value("STORE_SHIPMENT"));

const shipmentApiService = {
  shipmentItems: {
    ...baseApiService(apiClient, 'shipment_items'),
    init: (data) => apiClient.put("shipment_items:init", data, {
      headers: { "Content-Type": "application/ld+json" }
    }),
    multiPick: (data) => apiClient.put("shipment_items:multi_pick", data, {
      headers: { "Content-Type": "application/ld+json" }
    }),
    // Pick, pack and ship barcode (single item shipments)
    singlePack: (data) => apiClient.put("shipment_items:single_pack", data, {
      headers: { "Content-Type": "application/ld+json" }
    }),
    pack: (data) => apiClient.put("shipment_items:pack", data, {
      headers: { "Content-Type": "application/ld+json" }
    }),
    ship: (data) => apiClient.put("shipment_items:ship", data, {
      headers: { "Content-Type": "application/ld+json" }
    }),
    cancel: (data) => apiClient.put("shipment_items:cancel", data, {
      headers: { "Content-Type": "application/ld+json" }
    })
  },
  shipments: {
    ...baseApiService(apiClient, 'shipments'),
    shipAll: (data) => apiClient.put("shipments:ship_all", data, {
      headers: { "Content-Type": "application/ld+json" }
    }),
    statistics: (params) => apiClient.get("shipments:statistics" + apiServiceHelpers.buildQueryParams(params), {
      headers: { "Content-Type": "application/json" }
    })
  },
  pigeonholes: {
    ...baseApiService(apiClient, 'pigeonholes'),
    request: (data) => apiClient.post("pigeonholes:request", data, {
      headers: { "Content-Type": "application/ld+json" }
    }),
    check: (data) => apiClient.post("pigeonholes:check", data, {
      headers: { "Content-Type": "application/ld+json" }
    }),
    // Returns pigeonhole for location to pack
    pack: (data) => apiClient.post("pigeonholes:pack", data, {
      headers: { "Content-Type": "application/ld+json" }
    }),
    // Validate a pigeonhole if valid for barcode
    scanPigeonhole: (data) => apiClient.post("pigeonholes:scan", data, {
      headers: { "Content-Type": "application/ld+json" }
    }),
    // Scan article and get a pigeonhole if a previous pigeonhole was scanned.
    scanArticle: (data) => apiClient.post("barcodes:scan", data, {
      headers: { "Content-Type": "application/ld+json" }
    }),
  }
}

export default shipmentApiService